.page-background {
  height: 100vh; /* Viewport height to fill the whole screen */
  background-image: url('assets/img/bkg.jpg');
  background-position: center bottom; /* Aligns the bottom of the image with the bottom of the screen */
  background-size: cover; /* Ensures the image covers the viewport, may crop top/bottom or sides */
  background-repeat: no-repeat;
  padding-top: 32px;
}

.custom-card {
  margin-top: 1rem; /* Additional top margin */
  opacity: 0.9; /* Slightly transparent */
}

